import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { DeviceDetectorModule } from 'ngx-device-detector';

// Components/Routing
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './landing/landing.component';
// About components
    // Main
import { AboutComponent } from './about/about.component';
// Footer
import { FooterComponent } from './footer/footer.component';
// Presskit component
import { PresskitComponent } from './presskit/presskit.component';
import { PresskitPhotosetComponent } from './presskit-photoset/presskit-photoset.component';

// Angular Material
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';


@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    AboutComponent,
    FooterComponent,
    PresskitPhotosetComponent,
    PresskitComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DeviceDetectorModule.forRoot(),
    AppRoutingModule,
    MatButtonModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatDialogModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
 }
