import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-presskit',
  templateUrl: './presskit.component.html',
  styleUrls: ['./presskit.component.css']
})
export class PresskitComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
