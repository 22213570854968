<body>
  <!-- Landing bg -->
    <div id="landingBG" class="landing-background"></div>
    <!-- Header img -->
    <div class="landing-header">
      <!-- Desktop header img-->
     <img class="header-img-big" src="../../assets/title.png">
      <!-- Mobile header img-->
     <img class="header-img-small" src="../../assets/title_small.png">
 </div>
 <!-- ------- -->
 <!-- Buttons -->
 <!-- ------- -->
 <div class="landing-header-btn">
   <!-- Download button / Menu activator-->
   <button mat-button [matMenuTriggerFor]="downloadMenu" class="landing-btn">Download</button>
   <!-- Presskit button -->
   <button mat-button (click)="openPresskit()" class="landing-btn">Presskit</button>
     <!-- Choice menu download -->
     <mat-menu #downloadMenu="matMenu" yPosition="below" class="landing-download-menu">
         <!-- itch.io -->
         <a href="https://willfish-dev.itch.io/moonrisehope" target="_blank">
           <button  mat-menu-item>
             <span>at itch.io</span>
           </button>
         </a>
         <!-- Game Volt -->
         <a href="https://gamejolt.com/games/moonrisehope/463975" target="_blank">
           <button mat-menu-item>
             <span>at Game Volt</span>
           </button></a>
      </mat-menu>
    <!-- -------------- -->
    <!-- Social buttons -->
    <!-- -------------- -->
    <div class="social-content">
      <a href="https://twitter.com/WillFish_dev" target="_blank"><img class="svg" src="../../assets/icons/twitter_icon.svg"></a>
      <a href="https://discord.gg/GKRQcqY "target="_blank"><img class="svg" src="../../../assets/icons/discord_icon.svg"></a>
      <a href="https://www.instagram.com/willfish_gamedev/" target="_blank"><img class="svg" src="../../assets/icons/instagram_icon.svg"></a>
      <a href="mailto:contact@moonrisehope.com" target="_blank"><img class="svg" src="../../../assets/icons/email_icon.svg"></a>
    </div>
    </div>
</body>
