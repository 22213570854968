<body>
    <!-- Header / top content -->
        <div class="content-bgNull">
            <div class="about-header">
            </div>
        </div>
        <!-- Content | ??? About the game/dev ??? -->
        <!-- It could be also less/more parts of content -->
        <div class="about-content">
            <!-- First content part / Sample -->
            <!-- Wrapper for background image -->
            <div class="content-bgFirst">
                <!-- Content div -->
                <div class="about-contentFirst">
                    <!-- Header -->
                    <h2>Challenging battles that test wit and resolve over luck.</h2>
                    <!-- Content (later as extra component)-->
                    <img src="https://via.placeholder.com/640x360">
                </div>
            </div>
            <!-- Second content part  -->
            <div class="content-bgSecond">
                <div class="about-contentSecond">
                    <h2>Perform massive combos using your characters' synergistic skill-sets.</h2>
                </div>
            </div>
            <!-- Third content part -->
            <div class="content-bgThird">
                <div class="about-contentThird">
                    <h2>Experiment with the wealth of unique abilities.</h2>
                </div>
            </div>
            <!-- Fourth content part-->
            <div class="content-bgFourth">
                <div class="about-contentFourth">
                    <h2>Explore a world full of charming characters and mystery.</h2>
                </div>
            </div>

        </div>
</body>
