<mat-dialog-content>
<h1>Moonrise Hope: Presskit</h1>
<div class="div-master">
<div class="div-qf">
<!-- ----------- -->
<!-- Quick facts -->
<!-- ----------- -->   
<h2>Quick facts</h2>
<!-- Developer -->
<h3>Hat Wizards Games</h3>
<ul>
    <li>Developers: Troy Adams, Will Fish</li>
    <li>Coomposer: Timothy Ebert</li>
</ul>  
<!-- Release -->
<h3>Release/Progress</h3>
<ul>
    <li>Beta Development - Release Date TBD</li>
</ul>
<!-- Price -->
<h3>Price</h3>
<ul>
    <li>Free Demo</li>
</ul>
<!-- Platform -->
<h3>Platform</h3>
<ul>
    <li>PC, Linux, Mac</li>
</ul>
<!-- Store page -->
<h3>Store page</h3>
<ul>
    <li>Itch.io: <a href="https://willfish-dev.itch.io/moonrisehope">https://willfish-dev.itch.io/moonrisehope</a></li>
    <li>Gamejolt: <a href="https://gamejolt.com/games/moonrisehope/463975">https://gamejolt.com/games/moonrisehope/463975</a></li>
</ul>
<!-- Social -->
<h3>Social</h3>
<ul>
    <li>Twitter: <a href="https://twitter.com/WillFish_dev">@WillFish_dev</a></li>
    <li>Instagram: <a href="https://www.instagram.com/willfish_gamedev/">willfish_gamedev</a></li>
    <li>Discord <a href="https://discord.gg/GKRQcqY">https://discord.gg/GKRQcqY</a></li>
</ul>
<!-- Contact -->
<h3>Contact</h3>
<ul>
    <a href="mailto:contact@moonrisehope.com"><li>contact@moonrisehope.com</li></a>
    <a href="mailto:williamjacksonfisher@gmail.com"><li>williamjacksonfisher@gmail.com</li></a>
</ul>
</div>
<div class="div-ct">
<!-- ---------------- -->
<!-- Game description -->
<!-- ---------------- -->
<h2>The Game</h2>
<p>Moonrise Hope is a story-driven, turn-based tactics RPG about people pushing the limits of their resolve.
    Lead a party of monster hunters known as Candiffs in challenging RNG-less puzzle-strategy combat that
    rewards creativity as much as it does tactics. Witness your characters struggle forward and grow together,
    and decide when to push them further and exhaust their limited willpower.</p>
<!-- ----------------- -->
<!-- Story of the game -->
<!-- ----------------- -->
<h3>Story</h3>
<p>The game is about a tight-knit group of monster hunters fighting for others, figuring out the nature of their
    world, and learning about themselves. More will come soon, we’re currently revising the story so it can be
    the best it can be!</p>
<!-- ------------ -->
<!-- Key features -->
<!-- ------------ -->
<h2>Key features</h2>
<!-- RNG-Less Combat -->
<h3>RNG-Less Combat</h3>
<ul>
    <li>Plan out the perfect turns and create massive combos in our deep and challenging combat system.</li>
</ul>
<!-- Push Willpower to it’s Limit -->
<h3>Push Willpower to it’s Limit</h3>
<ul>
    <li>Use up your character's willpower to gain an edge in battle, but be careful they do not lose hope.
        Help maintain their willpower and hope in and out of combat.</li>
</ul>
<!-- Mature Character-driven Story -->
<h3>Mature Character-driven Story</h3>
<ul>
    <li>Watch your party grow and interact together as they fight with not only monsters but more elusive enemies of the mind.</li>
</ul>
<!-- Explore a Mysterious World -->
<h3>Explore a Mysterious World</h3>
<ul>
    <li>Lead your party through detailed pixel art environments, discover hidden secrets, and learn the stories of the local townsfolk.</li>
</ul>
<!-- Create your own Levels -->
<h3>Create your own Levels</h3>
<ul>
    <li>Features a comprehensive editor for you to create and share your own epic battles.</li>
</ul>
</div>
</div>
<div class="div-lc">
<!-- ----- -->
<!-- Media -->
<!-- ----- -->
<h2>Media</h2>
<app-presskit-photoset></app-presskit-photoset>
<a href="https://drive.google.com/file/d/1hQraNHSQKrzIiHsi-0z5mcV3qo2wpTxk/view?usp=sharing">https://drive.google.com/file/d/1hQraNHSQKrzIiHsi-0z5mcV3qo2wpTxk/view?usp=sharing</a>
<!-- ---------------- -->
<!-- Gameplay/Trailer -->
<!-- ---------------- -->
<h2>Gameplay/Trailer</h2>
<div class="div-video">
<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/L4hFHpaWtAk?controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope;
picture-in-picture" allowfullscreen></iframe>
</div>
<a href="https://youtu.be/L4hFHpaWtAk" target="_blank"><img alt="Moonrise Hope - Gameplay Trailer" src="../../assets/trailer_mobile.png" class="video-mobile"></a>
<!-- ------- -->
<!-- Reviews -->
<!-- ------- -->
<div class="div-rev">
<h2>Reviews</h2>
<!-- First review -->
<p>"A fun and challenging game!
    Great art!
    Great writing!
    Great controls!
    Great execution and work!"</p>
<a href="https://gamejolt.com/games/moonrisehope/463975">Nathan Auckett (Gamejolt Moderator) @Gamejolt</a>
<!-- Second review -->
<p>"WOW! Tactical RPG done right. Battles were fun and engaging from the outset. Diverse set of characters
    each with unique and tactically interesting abilities. And the story has me hooked!"</p>
<a href="https://twitter.com/ancalabro/status/1228209484788420608">Ancalabro @Twitter</a>
</div> 
   
<!-- ----- -->
<!-- Music -->
<!-- ----- -->    
</div>


</mat-dialog-content>
