import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-presskit-photoset',
  templateUrl: './presskit-photoset.component.html',
  styleUrls: ['./presskit-photoset.component.css']
})
export class PresskitPhotosetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
