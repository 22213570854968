import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PresskitComponent } from '../presskit/presskit.component';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css', './mquery.landing.component.css']
})
export class LandingComponent implements OnInit {
// Presskit popup
  constructor(public presskitPopup: MatDialog) {}

  openPresskit() {
    const popupRef = this.presskitPopup.open(PresskitComponent);
  }

  ngOnInit(): void {

  }
}
